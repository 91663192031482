import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import pdf from "../../Assets/../Assets/Resume-MANAN.pdf";
import { AiOutlineDownload, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./Resume.css";  // Custom CSS for overlay and positioning
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ResumeNew() {
  const [width, setWidth] = useState(1200);
  const [numPages, setNumPages] = useState(null); // Track number of pages
  const [pageNumber, setPageNumber] = useState(1); // Track current page

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  // Function to handle when the PDF is successfully loaded
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Function to go to the next page
  const goToNextPage = () => {
    setPageNumber(prevPage => Math.min(prevPage + 1, numPages));
  };

  // Function to go to the previous page
  const goToPreviousPage = () => {
    setPageNumber(prevPage => Math.max(prevPage - 1, 1));
  };

  return (
    <div id="resume">
      <Container fluid className="resume-section">
        <Particle />
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={pdf}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download Resume
          </Button>
        </Row>

        <Row className="resume">
          <Document 
            file={pdf} 
            onLoadSuccess={onDocumentLoadSuccess} 
            className="d-flex justify-content-center"
          >
            <Page 
              pageNumber={pageNumber} 
              scale={width > 786 ? 1.7 : 0.6} 
            />
          </Document>
          
          {/* Page navigation overlay */}
          <div className="pdf-navigation-overlay">
            <AiOutlineLeft 
              className="navigation-icon" 
              onClick={goToPreviousPage} 
              style={{ cursor: pageNumber > 1 ? 'pointer' : 'not-allowed' }} 
            />
            <span className="page-number">Page {pageNumber} of {numPages}</span>
            <AiOutlineRight 
              className="navigation-icon" 
              onClick={goToNextPage} 
              style={{ cursor: pageNumber < numPages ? 'pointer' : 'not-allowed' }} 
            />
          </div>
        </Row>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={pdf}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download Resume
          </Button>
        </Row>

      </Container>
    </div>
  );
}

export default ResumeNew;
