import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";

function Skillset() {
    return (
        <div id="skillset">
            <Container fluid className="about-section">
                <Particle />
                <Container>
                    <h1 className="project-heading">
                        Professional <strong className="purple">Skillset </strong>
                    </h1>

                    <Techstack />

                    <h1 className="project-heading">
                        <strong className="purple">Tools</strong> I use
                    </h1>
                    <Toolstack />

                    <Github />
                </Container>
            </Container>
        </div>
    );
}

export default Skillset;
